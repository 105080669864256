<template>
    <div class="phone-container">
        <img id="phone" src="../assets/iphone.png" alt="linkbus app"/>
    </div>
</template>

<script>
    export default {
        name: "Phone"
    }
</script>

<style scoped>
    .phone-container {
        /*background-image: url("../assets/iphone.png");*/
        /*!*background-attachment: fixed;*!*/
        /*background-repeat: no-repeat;*/
        /*background-size: contain;*/
        /*width: 100%;*/
        /*height: 500px;*/
    }
    #phone {
        width: 100%;
    }
</style>